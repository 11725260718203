<template>
  <v-container style="height: 100%">
    <v-row class="pa-1">
      <v-col cols="12" v-for="(aCase, index) in cases" :key="index">
        <v-card
          class="mb-1 scale-up-center"
          style="border-radius: 11px;"
          rounded="lg"
          elevation="9"
          @click="$router.push({name: 'atendimento', params: {id: aCase.id, externalProtocol: aCase.xipp_external_protocol}}) "
        >
          <v-card-text>
            <v-row class="px-3">
              <v-col cols="12">
                <p class="atendimento-title pb-0 mb-0" style="font-size: 18px">
                  {{ aCase.name ? aCase.name : '' }}
                </p>
              </v-col>

              <v-col cols="12" class="font-weight-bold py-0 my-0">Benefício / Operadora</v-col>
              <v-col cols="12" class="pt-0">
                <p class="font-weight-light py-0 my-0">
                  {{ aCase.benefitDescribe && aCase.benefitDescribe }} /
                  {{ aCase.xipp_contract_id ? (aCase.xipp_contract_id.carrier_id ? aCase.xipp_contract_id.carrier_id.xipp_commercial_name : '') : '' }}
                </p>
              </v-col>

              <v-col cols="5" class="py-0 font-weight-bold">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    Protocolo
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <p class="font-weight-light">{{ aCase.id ? aCase.id : '' }}</p>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="7" class="py-0 font-weight-bold">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    Abertura
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <p class="font-weight-light">{{ aCase.create_date ? formatDate(aCase.create_date) : ''}}</p>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="7" class="py-0 font-weight-bold">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    Atendido
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <p class="font-weight-light">{{ aCase.xipp_attendee_name ? aCase.xipp_attendee_name : ''}}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContractService from "@/common/ContractService";
import { mapGetters, mapMutations } from "vuex";
import Vue from "vue";

export default {
  props: {
    cases:{
      type: Array,
      required: true
    }
  },
  async created() {
    this._contractservice = new ContractService();
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapMutations(['loading'])
  },
  methods : {
    formatDate ( dateString ) {
      if (!dateString) return null;
      return Vue.filter('formatDate')(dateString)
    }
  }
};
</script>
<style scoped>
.atendimento-type{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  /* identical to box height */
  /* grey / 3 */
  color: #868698;
}
.atendimento-title{
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  color: #532E88;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.3s ease-in both;
  animation: scale-up-center 0.3s ease-in both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

</style>
